export interface Plaintext {
  id: string;
  text: string;
  author: string;
  origin: string;
  notes?: string;
}

const plaintexts: Plaintext[] = [
  {
    id: "0",
    text: "The feverish interest aroused at the meeting by Legrasse's tale, corroborated as it was by the statuette, is echoed in the subsequent correspondence of those who attended; although scant mention occurs in the formal publications of the society. Caution is the first care of those accustomed to face occasional charlatanry and imposture. Legrasse for some time lent the image to Professor Webb, but at the latter's death it was returned to him and remains in his possession, where I viewed it not long ago. It is truly a terrible thing, and unmistakably akin to the dream-sculpture of young Wilcox.",
    author: "H.P. Lovecraft",
    origin: "excerpt from *The Call of Cthulhu*",
  },
  {
    id: "1",
    text: "Above these apparent hieroglyphics was a figure of evident pictorial intent, though its impressionistic execution forbade a very clear idea of its nature. It seemed to be a sort of monster, or symbol representing a monster, of a form which only a diseased fancy could conceive. If I say that my somewhat extravagant imagination yielded simultaneous pictures of an octopus, a dragon, and a human caricature, I shall not be unfaithful to the spirit of the thing. A pulpy, tentacled head surmounted a grotesque and scaly body with rudimentary wings; but it was the general outline of the whole which made it most shockingly frightful. Behind the figure was a vague suggestions of a Cyclopean architectural background",
    author: "H.P. Lovecraft",
    origin: "excerpt from *The Call of Cthulhu*",
  },
  {
    id: "2",
    text: "Then Horton stopped walking. The speck-voice was talking! The voice was so faint he could just barley hear it. “Speak up, please” said Horton. He put his ear near it. “My friend,” came the voice, “you’re a very fine friend. You’ve helped all us folks on this dust speck no end. You’ve saved all our houses, our ceilings and floors. You’ve saved all our churches and grocery stores.” “You mean...” Horton gasped, “you have buildings there, too?” “Oh, yes,” piped the voice. “We most certainly do... “I know,” called the voice, “I’m too small to be seen but I’m mayor of a town that is friendly and clean. Our buildings, to you, would seem terribly small but to us, who aren’t big, they are wonderfully tall. My town is called Who-ville, for I am a Who and we Whos are all thankful and grateful to you.” And Horton called back to the Mayor of the town, “You’re safe now. Don’t worry. I won’t let you down.” ",
    author: "Dr. Seuss",
    origin: "excerpt from *Horton Hears a Who*",
  },
  {
    id: "3",
    text: "And the Grinch, with his Grinch-feet ice cold in the snow, stood puzzling and puzzling, how could it be so? It came without ribbons. It came without tags. It came without packages, boxes or bags. And he puzzled and puzzled 'till his puzzler was sore. Then the Grinch thought of something he hadn't before. What if Christmas, he thought, doesn't come from a store. What if Christmas, perhaps, means a little bit more?",
    author: "Dr. Seuss",
    origin: "excerpt from *How the Grinch Stole Christmas*",
  },
  {
    id: "4",
    text: "Every inch of wall space is covered by a bookcase. Each bookcase has six shelves, going almost to the ceiling. Some bookshelves are stacked to the brim with hardback books: science, maths, history, and everything else. Other shelves have two layers of paperback science fiction, with the back layer of books propped up on old tissue boxes or lengths of wood, so that you can see the back layer of books above the books in front. And it still isn't enough. Books are overflowing onto the tables and the sofas and making little heaps under the windows.",
    author: "Eliezer Yudkowsky",
    origin: "excerpt from *Harry Potter and the Methods of Rationality*",
  },
  {
    id: "5",
    text: 'Medicine-as-commerce is at the heart of each of these stories just as it is at the heart of some of the good trends and most of the bad ones. It is clear enough that biotech and pharmaceutical firms can work miracles. But it is also true that they can lean heavily on public funding and end up making a great deal of private profit. Even more troublesome are the rapidly growing investor-owned health plans. They go under many names, including health-maintenance organizations. Although some of these are not-for-profit, many have in common a basic strategy: selling "Product" to "Consumers" rather than providing care to patients.',
    author: "Paul Farmer",
    origin: "excerpt from *Pathologies of Power*",
  },
  {
    id: "6",
    text: "I imagined the Death Messenger as an ugly old man with horns and ulcerous skin, burning yellow eyes and a gaping, toothless mouth that waited to feed ravenously on the souls that lined up in front of our apartment. Our open door was Saja's gaping mouth, my mother his tongue, sampling each person for the taste of death. The demon waiting to snatch me off to hell if I did not carry a red-packeted charm, Saja was the devil my father had preached about and, through my mother's chants and offerings, became more real to me than my father ever was.",
    author: "Nora Okja Keller",
    origin: "excerpt from *Comfort Woman*",
    notes: "The J is likely difficult to figure out.",
  },
  {
    id: "7",
    text: "Mondays or Fridays, it doesn't matter, mornings always go by slow and this day especially. But lunchtime came finally and I got to get in line with the stay-at-school kids. Everything is fine until the nun who knows all the canteen kids by heart looks at me and says: You, who sent you here? And since I am shy, I don't say anything, just hold out my hand with the letter. This is no good, she says, till Sister Superior gives the okay. Go upstairs and see her. And so I went.",
    author: "Sandra Cisneros",
    origin: "excerpt from *The House on Mango Street*",
  },
  {
    id: "8",
    text: "At a few minutes past the appointed hour Mrs. Trent entered, unannounced. She was a woman of about twenty-eight. She had a white, demure, saintlike face, smooth black hair, and lips so crimson and full that they seemed to be bursting with blood. Her tall, graceful body was most expensively attired. Kisses were exchanged between her and Mrs. Jameson. She bowed to the rest of the assembly, and stole a half glance and a smile at Faull. The latter gave her a queer look, and Backhouse, who lost nothing, saw the concealed barbarian in the complacent gleam of his eye.",
    author: "David Lindsay",
    origin: "excerpt from *A Voyage to Arcturus*",
    notes: "Courtesy of Diego",
  },
  {
    id: "9",
    text: "He had a rich abundance of idle time, but it never hung heavy on his hands, for he interested himself in every new thing that was born into the universe of ideas, and studied it and experimented upon it at his house. One of his pet fads was palmistry. To another one he gave no name, neither would he explain to anybody what its purpose was, but merely said it was an amusement. In fact, he had found that his fads added to his reputation as a pudd'nhead; therefore he was growing chary of being too communicative about them.",
    author: "Mark Twain",
    origin: "excerpt from *Pudd'nhead Wilson*",
    notes: "From one of Jay's book collection",
  },
  {
    id: "10",
    text: "She was stretched on her back beneath the pear tree soaking in the alto chant of the visiting bees, the gold of the sun, and the panting breath of the breeze when the inaudible voice of it all came to her. She saw a dust-bearing bee sink into the sanctum of a bloom; the thousand sister-calyxes arch to meet the love embrace and the ecstatic shiver of the tree from root to tiniest branch creaming in every blossom and frothing with delight. So this was marriage! She had been summoned to behold a revelation. Then Janie felt a pain remorseless sweet that left her limp and languid.",
    author: "Zora Neale Hurston",
    origin: "excerpt from *Their Eyes Were Watching God*",
  },
  {
    id: "11",
    text: "The scientific soul of matter, its irreducible essence, was mass. Mass defined matter's resistance to motion, its inertia. Mass was unchangeable, \"conserved.\" It could be transferred from one body to another but could never be gained or lost. For Newton, mass defined quality of matter. In Newton's physics, mass provided the link between force and motion, and it provided the source of gravity. For Lavoisier, the persistence of mass, its accurate conservation, provided the foundation of chemistry and offered a fruitful guide to discovery. If mass seems to disappear, look for it in new forms",
    author: "Frank Wilczek",
    origin: "excerpt from *The Lightness of Being*",
  },
  {
    id: "12",
    text: "It seems probable to me, that God in the beginning formed matter in solid, massy, hard, impenetrable, moveable particles, of such sizes and figures, and with such other properties, and in such proportions to space, as most conducted to the ends for which He formed them; and that these primitive particles being solids, are incomparably harder than any porous bodies compounded of them, even so very hard, as never to wear or break in pieces; no ordinary power being able to divide what God Himself made one in the first creation.",
    author: "Sir Issac Newton",
    origin: "excerpt from *Opticks*",
  },
  {
    id: "13",
    text: "Lo-lee-ta: the tip of the tongue taking a trip of three steps down the palate to tap, at three, on the teeth. Lo. Lee. Ta. She was Lo, plain Lo, in the morning, standing four feet ten in one sock. She was Lola in slacks. She was Dolly at school. She was Dolores on the dotted line. But in my arms she was always Lolita. Did she have a precursor? She did, indeed she did. In point of fact, there might have been no Lolita at all had I not loved, one summer, an initial girl-child. In a princedom by the sea. Oh when? About as many years before Lolita was born as my age was that summer. You can always count on a murderer for a fancy prose style. Ladies and gentlemen of the jury, exhibit number one is what the seraphs, the misinformed, simple, noble-winged seraphs, envied. Look at this tangle of thorns.",
    author: "Vladimir Nabokov",
    origin: "excerpt from *Lolita*",
    notes: "Courtesy of /u/double-indemnity from Reddit.",
  },
  {
    id: "14",
    text: "Until a man is twenty-five, he still thinks, every so often, that under the right circumstances he could be the baddest motherfucker in the world. If I moved to a martial-arts monastery in China and studied real hard for ten years. If my family was wiped out by Colombian drug dealers and I swore myself to revenge. If I got a fatal disease, had one year to live, and devoted it to wiping out street crime. If I just dropped out and devoted my life to being bad. Hiro used to feel this way, too, but then he ran into Raven. In a way, this was liberating. He no longer has to worry about being the baddest motherfucker in the world. The position is taken.",
    author: "Neal Stephenson",
    origin: "excerpt from *Snowcrash*",
    notes: "Courtesy of /u/paiaw, from the top of /r/excerpts",
  },
  {
    id: "15",
    text: "I wanted so badly to lie down next to her on the couch, to wrap my arms around her and sleep. Not fuck, like in those movies. Not even have sex. Just sleep together, in the most innocent sense of the phrase. But I lacked the courage and she had a boyfriend and I was gawky and she was gorgeous and I was hopelessly boring and she was endlessly fascinating. So I walked back to my room and collapsed on the bottom bunk, thinking that if people were rain, I was drizzle and she was hurricane.",
    author: "John Green",
    origin: "excerpt from *Looking for Alaska*",
    notes: "Courtesy of /u/extrachange from the top of /r/bookquotes",
  },
  {
    id: "16",
    text: "In  the  specific  context  of  the  Rock - Paper - Scissors game,  the  replicator  equations  are  most  often  studied in the absence of mutation.  The dynamics in that case tend to exhibit one of three types of long-term behavior,  depending  on  a  parameter that  characterizes  how  far  the  game  is  from  being  a  zero-sum  game. The three types of behavior are:  (1) stable coexistence of all three species, (2) neutrally stable cycles that fill the whole state space, and (3) large-amplitude heteroclinic cycles in which each species in turn almost takes over the whole population and then almost goes extinct.",
    author: "Danielle  F.  P.  Toupo  and  Steven  H.  Strogatz",
    origin:
      "excerpt from the paper *Nonlinear Dynamics of the Rock-Paper-Scissors Game with Mutations*",
    notes: "Jenette's suggestion",
  },
  {
    id: "17",
    text: "Fool, prate not to me about covenants. There can be no covenants between men and lions, wolves and lambs can never be of one mind, but hate each other out and out and through. Therefore there can be no understanding between you and me, nor may there be any covenants between us, till one or other shall fall and glut grim Mars with his life's blood. Put forth all your strength; you have need now to prove yourself indeed a bold soldier and man of war. You have no more chance, and Pallas Minerva will forthwith vanquish you by my spear: you shall now pay me in full for the grief you have caused me on account of my comrades whom you have killed in battle.",
    author: "Homer",
    origin: "excerpt from *The Iliad*, translated by Samuel Butler",
  },
  {
    id: "18",
    text: "When you're young, psychedelic is a primary color and a most mesmerizing high. Santa Monica was full of free multihued trips. The color-burst free love murals on Main Street seemed to come to vibrant cartoon life when I passed them. The whales and dolphins frolicked in the clouds and the sea lions and merry-go-round horsies turned cartwheels in the street. The spray-any-color-paint-on-the-spin-art creations at the pier were fifty-cent Jackson Pollock rainbow heroin hits that made your skin tingle and the grains of sand swell up and rise to the sky like helium balloons. Looking into the kaleidoscopic eyes of a scruffy Bukowski barfly sitting in the lotus position along with the bike trails fractured your soul into hundreds of disconnected psychedelic shards. Each sharp piece of your mind begging for sobriety.",
    author: "Paul Beatty",
    origin: "excerpt from *The White Boy Shuffle*",
  },
  {
    id: "19",
    text: "Inside this box are crisp, versatile, little crackers with surprising big flavor. Why surprising? Because they have fifty percent less fat than our original Woven Wheats Wafers. That's why we call them Reduced Guilt. But really, a reduced fat snack still has to deliver on flavor and texture, right? This one does. And then some. Try right out of the box, topped with cheese, or as an accompaniment for soups or salad.",
    author: "Trader Joe's",
    origin: "excerpt from a box of *Woven Wheats Wafers*",
  },
  {
    id: "20",
    text: "We have found that two kinds of people exist in the world: those who accept the nonsensical and reinforced views of the masses, bowing attentively and unabashedly to its whims, without concern for the ultimate and inevitable ramifications of their mindless submission; and you. You, the thoughtful, intelligent, questioning, sentient being who is awakened and enlightened to the whirlwind of events and ideas about him while standing firm against the onslaught of skewed political ideologies and jaded personal agendas that pound against our doors of sensibility day in and day out, testing the integrity of the deadbolt we have named our freedom to think independently.",
    author: "the IHateCilantro.com staff",
    origin: "excerpt from the 'About' page of *IHateCilantro.com*",
  },
  {
    id: "21",
    text: 'Your vehicle is equipped with "all season tires" which are designed to provide an adequate measure of traction, handling, and braking performance in year-round driving. In winter, it may be possible to enhance performance through use of tires designed specifically for winter driving. If you choose to install winter tires on your vehicle, be sure to use the same tire size, construction, brand, and load range as the original tires.',
    author: "Fuji Heavy Industries, LTD.",
    origin: "excerpt from the *Subaru Impreza 1997 Owner's Manual*",
  },
  {
    id: "22",
    text: "I made a mechanical dragon \nOf bottle tops, hinges, and string, \nOf thrown away clocks and unmendable socks, \nOf hangers and worn innersprings. \nI built it of cardboard and plastic, \nOf doorknobs and cables and corks, \nOf spools and balloons and unusable spoons, \nAnd rusty old shovels and forks. It's quite an unusual dragon \nIt rolls on irregular wheels, \nIt clatters and creaks and it rattles and squeaks, \nAnd when it tips over, it squeals. \nI've tried to control its maneuvers, \nIt fails to obey my commands, \nIt bumps into walls till it totters and falls \nI made it myself with my hands!",
    author: "Jack Prelutsky",
    origin: "poem from *The Dragons are Singing Tonight",
  },
  {
    id: "23",
    text: "He never looked better, nor had he been loved more, nor had the breeding of his animals been wilder. There was a slaughtering of so many cows, pigs, and chickens for the endless parties that the ground in the courtyard turned black and muddy with so much blood. It was an eternal execution ground of bones and innards, a mud pit of leftovers, and they had to keep exploding dynamite bombs all the time so that the buzzards would not pluck out the guests' eyes",
    author: "Gabriel García Márquez",
    origin: "excerpt from *One Hundred Years of Solitude*",
  },
  {
    id: "24",
    text: "Those who came before us made certain that this country rode the first waves of the industrial revolutions, the first waves of modern invention, and the first wave of nuclear power, and this generation does not intend to founder in the backwash of the coming age of space. We mean to be a part of it—we mean to lead it. For the eyes of the world now look into space, to the moon and to the planets beyond, and we have vowed that we shall not see it governed by a hostile flag of conquest, but by a banner of freedom and peace. We have vowed that we shall not see space filled with weapons of mass destruction, but with instruments of knowledge and understanding.",
    author: "John F. Kennedy",
    origin:
      "excerpt from *Address at Rice University on the Nation's Space Effort*",
  },
  {
    id: "25",
    text: "Here's the thing. You said a \"jackdaw is a crow.\"\n Is it in the same family? Yes. No one's arguing that.\nAs someone who is a scientist who studies crows, I am telling you, specifically, in science, no one calls jackdaws crows. If you want to be \"specific\" like you said, then you shouldn't either. They're not the same thing.\nIf you're saying \"crow family\" you're referring to the taxonomic grouping of Corvidae, which includes things from nutcrackers to blue jays to ravens.\nSo your reasoning for calling a jackdaw a crow is because random people \"call the black ones crows?\" Let's get grackles and blackbirds in there, then, too. \n Also, calling someone a human or an ape? It's not one or the other, that's not how taxonomy works. They're both. A jackdaw is a jackdaw and a member of the crow family. But that's not what you said. You said a jackdaw is a crow, which is not true unless you're okay with calling all members of the crow family crows, which means you'd call blue jays, ravens, and other birds crows, too. Which you said you don't. It's okay to just admit you're wrong, you know?",
    author: "/u/Unidan",
    origin: "comment originally posted on *reddit.com</br>",
  },
  {
    id: "26",
    text: "We are challenging the FBI’s demands with the deepest respect for American democracy and a love of our country. We believe it would be in the best interest of everyone to step back and consider the implications. While we believe the FBI’s intentions are good, it would be wrong for the government to force us to build a backdoor into our products. And ultimately, we fear that this demand would undermine the very freedoms and liberty our government is meant to protect.",
    author: "Tim Cook",
    origin: "excerpt from Apple's open letter *A Message to Our Customers*",
  },
  {
    id: "27",
    text: "This is our world now... the world of the electron and the switch, the beauty of the baud. We make use of the service already existing without paying for what could be dirt-cheap if it wasn't run by profiteering gluttons, and you call us criminals. We explore... and you call us criminals. We seek after knowledge, and you call us criminals. We exist without skin color, without nationality, without religous bias... and you call us criminals. You build atomic bombs, you wage wars, you murder, cheat, and lie to us and try to make us believe it's for our own good, yet we're the criminals.",
    author: "Loyd Blankenship",
    origin: "excerpt from *The Conscience of a Hacker*",
  },
  {
    id: "28",
    text: "You keep it going man\n You keep those books rolling\n You pick up all those books that you're gonna read and not remember\n And you roll, man\n You get that associate's degree, okay?\n Then you get your bachelor's\n Then you get your masters\n Then you get your masters' masters\n Then you get your doctorate\n You go man!\n And then when everyone says quit\n You show them those degrees, man\n When everyone says \"Hey, you're not working, you're not making any money\"\n You say \"You look at my degrees, and you look at my life\n Yeah, I'm fifty-two! So what?\n Hate all you want, but I'm smart, I'm so smart\n And I'm in school\n All these guys out here making money all these ways\n And I'm spending mine to be smart!\n You know why?\n Cause when I die buddy\n You know what's gonna keep me warm?\n That's right, those degrees.\"",
    author: "Kanye West",
    origin: "exerpt from *School Spirit (Skit 2)* on Graduation",
    notes: "plz don't sue me kanye",
  },
  {
    id: "29",
    text: "If I may add a personal note, may I say that I know full-well that all mankind have been subjected, without any opposition, for so long to the anti-God theoretical astronomy which kills in early youth all respect for the Creator's statements regarding the construction of the earth and the heavens, that only one here and there will pay any attention whatsoever to any ancient and fighting contrary thought. But I have seen just how ready and keenly interested the youth of today can become when they realise that equally scientific arguments can be brought to bear upon that which has been withheld from them and which they have been indoctrinated to believe is a laughable crank subject. Therefore over the last few years I have made every effort to reach the youthful minds",
    author: "Samuel Shenton",
    origin:
      "excerpt from a personal correspondence between *Samuel Shenton and Ellis Hillman*",
  },
  {
    id: "30",
    text: "As for those three red-faced card players\u2014they are the guards. One smells of garlic, another of beer; but they're not a bad lot. They have wives they are afraid of, kids who are afraid of them; they're bothered by the little day-to- day worries that beset us all. At the same time\u2014they are policemen: eternally innocent, no matter what crimes are committed; eternally indifferent, for nothing that happens can matter to them. They are quite prepared to arrest anybody at all, including Creon himself, should the order be given by a new leader.",
    author: "Jean Anouilh",
    origin: "Excerpt from *Antigone*",
    notes: "Via Sparknotes",
  },
  {
    id: "31",
    text: "Every kind of stillness. The hush when the executioner's ax goes up at the end of the last act. The unbreathable silence when, at the beginning of the play, the two lovers, their hearts bared, their bodies naked, stand for the first time face to face in the darkened room, afraid to stir. The silence inside you when the roaring crowd acclaims the winner\u2014so that you think of a film without a sound track, mouths agape and no sound coming out of them, a clamor that is not more than picture; and you, the victor, already vanquished, alone in the desert of your silence. That is tragedy.",
    author: "Jean Anouilh",
    origin: "Excerpt from *Antigone*",
    notes: "Via Sparknotes",
  },
  {
    id: "32",
    text: "For a moment, or moments, it was as it had been in the beginning, before fear, before evil, before death, at the time of the creation, when the earth was new and living things flourished therein, where the earth was fair and all living things dwelt together as kindred. For a moment, or moments, beasts and children were friends, there in the sweetness and silence of the night, there in the calm and lovely fields of the Lord.",
    author: "Glendon Swarthout",
    origin: "Excerpt from *Bless the Beasts and Children*",
    notes: "Via Sparknotes",
  },
  {
    id: "33",
    text: "Born on this preserve, fear of men had been bred out of them. Inoculated against disease, they were prime. Fed hay when winter snows covered their browse, they followed a feed truck about like sheep. They had never known the arrow or the lance, the lightning or the fire which crazed their ancestors over cliffs and into swollen rivers, nor had they known, until yesterday, the sounds and implication of a gun.",
    author: "Glendon Swarthout",
    origin: "Excerpt from *Bless the Beasts and Children*",
    notes: "Via Sparknotes",
  },
  {
    id: "34",
    text: "She many times met with men of that district who said to her, \u201cWoman, give up this life that you lead and go and spin, and card wool, as other women do, and do not suffer so much shame and so much unhappiness. We would not suffer so much for any money on earth.\u201d Then she said to them, \u201cI do not suffer as much sorrow as I would do for our Lord\u2019s love, for I only suffer cutting words, and our merciful Lord Christ Jesus. … suffered hard strokes, bitter scourgings, and shameful death at the last, for me and for all mankind, blessed may he be.",
    author: "Margery Kempe",
    origin: "Excerpt from *The Book of Margery\u00a0Kempe*",
    notes: "Via Sparknotes",
  },
  {
    id: "35",
    text: "The devastation unloosed on Muslim societies in our day by fundamentalism … seems to be not merely the erasure of the living, oral, ethical, and human traditions of Islam but the literal destruction of and annihilation of the Muslims who are the bearers of those traditions. In Algeria, Iran, Afghanistan, and, alas, in Egypt, this narrow, violent variant of Islam is ravaging its way through the land.",
    author: "Leila Ahmed",
    origin: "Excerpt from *A Border Passage*",
    notes: "Via Sparknotes",
  },
  {
    id: "36",
    text: "Jess drew the way some people drank whiskey. The peace would start at the top of his muddled brain and seep down through his tired and tensed-up body. Lord, he loved to draw. Animals, mostly. Not regular animals like Miss Bessie and the chickens, but crazy animals with problems\u2014for some reason he liked to put his beasts into impossible fixes\u2026 He would like to show his drawings to his dad, but he didn't dare. When he was in first grade, he told his father than he wanted to be an artist when he grew up. He'd thought he would be pleased. He wasn't. 'What are they teaching in that damn school?' he had asked. 'Bunch of old ladies turning my son into some kind of a\u2014' He had stopped on the word, but Jess had gotten the message. It was one you didn't forget, even after four years.",
    author: "Katherine Paterson",
    origin: "Excerpt from *Bridge to Terabithia*",
    notes: "Via Sparknotes",
  },
  {
    id: "37",
    text: "Above all, do not lie to yourself. A man who lies to himself and listens to his own lie comes to a point where he does not discern any truth either in himself or anywhere around him, and thus falls into disrespect towards himself and others. Not respecting anyone, he ceases to love, and having no love, he gives himself up to the passions and coarse pleasures, in order to occupy and amuse himself, and in his vices reaches complete bestiality, and it all comes from lying continually to others and to himself.",
    author: "Fyodor Dostoevsky",
    origin: "Excerpt from *The Brothers Karamazov*",
    notes: "Via Sparknotes",
  },
  {
    id: "38",
    text: "Decide yourself who was right: you or the one who questioned you then? Recall the first question; its meaning, though not literally, was this: \u2018You want to go into the world, and you are going empty-handed, with some promise of freedom, which they in their simplicity and innate lawlessness cannot even comprehend, which they dread and fear\u2014for nothing has ever been more insufferable for man and for human society than freedom! But do you see these stones in this bare, scorching desert? Turn them into bread and mankind will run after you like sheep, grateful and obedient, though eternally trembling lest you withdraw your hand and your loaves cease for them.\u2019",
    author: "Fyodor Dostoevsky",
    origin: "Excerpt from *The Brothers Karamazov*",
    notes: "Via Sparknotes",
  },
  {
    id: "39",
    text: 'The word he chose to express "fragile" was filled with the intricacies of a continuing process, and with a strength inherent in spider webs woven across paths through sand hills where early in the morning the sun becomes entangled in each filament of web. It took a long time to explain the fragility and intricacy because no word exists alone, and the reason for choosing each word had to be explained with a story about why it must be said this certain way. That was the responsibility that went with being human, old Ku\'oosh said, the story behind each word must be told so there could be no mistake in the meaning of what had been said; and this demanded great patience and love.',
    author: "Leslie Marmon Silko",
    origin: "Excerpt from *Ceremony*",
    notes: "Via Sparknotes",
  },
  {
    id: "40",
    text: "Here they were, trying to bring back that old feeling, that feeling they belonged to America the way they felt during the war. They blamed themselves for losing the new feeling; they never talked about it, but they blamed themselves just like they blamed themselves for losing the land the white people took. They never thought to blame the white people for any of it; they wanted white people for their friends. They never saw that it was the white people who gave them that feeling and it was the white people who took it away again when the war was over.",
    author: "Leslie Marmon Silko",
    origin: "Excerpt from *Ceremony*",
    notes: "Via Sparknotes",
  },
  {
    id: "41",
    text: "It's fashionable to say that a man needs no more than six feet of earth. But six feet are what a corpse needs, not a man. And they say, too, now, that if our intellectual classes are attracted to the land and yearn for a farm, it's a good thing. But these farms are just the same as six feet of earth. To retreat from town, from the struggle, from the bustle of life, to retreat and bury yourself in your farm\u2014it's not life, it's egotism, laziness, it's monasticism of a sort, but monasticism without good works. A man does not need six feet of earth or a farm, but the entire globe, all nature, where he can have room to display all the qualities and peculiarities of his free spirit.",
    author: "Anton Chekhov",
    origin: "Excerpt from *Chekhov Stories*",
    notes: "Via Sparknotes",
  },
  {
    id: "42",
    text: "All Russia is our orchard. The earth is so wide, so beautiful, so full of wonderful places. Just think, Anya. Your grandfather, your great-grandfather and all your ancestors owned serfs, they owned human souls. Don't you see that from every cherry-tree in the orchard, from every leaf and every trunk, men and women are gazing at you? if we're to start living in the present isn't it abundantly clear that we've first got to redeem our past and make a clean break with it? And we can only redeem it by suffering and getting down to real work for a change.",
    author: "Anton Chekhov",
    origin: "Excerpt from *The Cherry Orchard*",
    notes: "Via Sparknotes",
  },
  {
    id: "43",
    text: "For all their achievements ... for all their mastery of the physical universe, his people were no better than a tribe that had passed its whole existence upon some flat and dusty plain ... yet, Karellen knew, they would hold fast until the end: they would await without despair whatever destiny was theirs. They would serve the Overmind because they had no choice, but even in that service would not lose their souls.",
    author: "Arthur C. Clarke",
    origin: "Excerpt from *Childhood's End*",
    notes: "Via Sparknotes",
  },
  {
    id: "44",
    text: "I stood in that room for a long time, watching the sunlight and listening to the sounds on the street outside. I stood there, tasting the room and the sunlight and the sounds, and thinking of the long hospital ward. … I wondered if little Mickey had ever seen sunlight come though the windows of a front room apartment. … Somehow everything had changed. I had spent five days in a hospital and the world around seemed sharpened now and pulsing with life.",
    author: "Chaim Potok",
    origin: "Excerpt from *The Chosen*",
    notes: "Via Sparknotes",
  },
  {
    id: "45",
    text: "What was taking place in him was totally unfamiliar, new, sudden, never before experienced. Not that he understood it, but he sensed clearly, with all the power of sensation, that it was no longer possible for him to address these people in the police station, not only with heartfelt effusions, as he had just done, but in any way at all, and had they been his own brothers and sisters, and not police lieutenants, there would still have been no point in this addressing them, in whatever circumstances of life.",
    author: "Fyodor Dostoevsky",
    origin: "Excerpt from *Crime and Punishment*",
    notes: "Via Sparknotes",
  },
  {
    id: "46",
    text: "What is it, to run away! A mere formality; that\u2019s not the main thing; no, he won\u2019t run away on me by a law of nature, even if he has somewhere to run to. Have you ever seen a moth near a candle? Well, so he\u2019ll keep circling around me, circling around me, as around a candle; freedom will no longer be dear to him, he\u2019ll fall to thinking, get entangled, he\u2019ll tangle himself all up as in a net, he\u2019ll worry himself to death! … he\u2019ll keep on making circles around me, narrowing the radius more and more, and\u2014whop! He\u2019ll fly right into my mouth, and I\u2019ll swallow him, sir, and that will be most agreeable, heh, heh, heh!",
    author: "Fyodor Dostoevsky",
    origin: "Excerpt from *Crime and Punishment*",
    notes: "Via Sparknotes",
  },
  {
    id: "47",
    text: "I think I would make a very good astronaut. To be a good astronaut you have to be intelligent and I\u2019m intelligent. You also have to understand how machines work and I\u2019m good at understanding how machines work. You also have to be someone who would like being on their own in a tiny space-craft thousands and thousands of miles away\u2026",
    author: "Mark Haddon",
    origin: "Excerpt from *The Curious Incident of the Dog in the Night-time*",
    notes: "Via Sparknotes",
  },
  {
    id: "48",
    text: "The Bible represents a fundamental guidepost for millions of people on the planet, in much the same way the Koran, Torah, and Pali Canon offer guidance to people of other religions. If you and I could dig up documentation that contradicted the holy stories of Islamic belief, Judaic belief, Buddhist belief, pagan belief, should we do that? Should we wave a flag and tell the Buddhists that the Buddha did not come from a lotus blossom? Or that Jesus was not born of a literal virgin birth? Those who truly understand their faiths understand the stories are metaphorical.",
    author: "Dan Brown",
    origin: "Excerpt from *The Da Vinci Code*",
    notes: "Via Sparknotes",
  },
  {
    id: "49",
    text: "He felt that sitting out here, he was not lonely; or if he was, that he felt on good terms with the loneliness; that he was a homesick man, and that here on the rock, though he might be more homesick than ever, he was well. He knew that a very important part of his well-being came of staying a few minutes away from home, very quietly, in the dark, listening to the leaves if they moved, and looking at the stars; and that his own, Rufus' own presence, was fully as indispensable to this well-being.",
    author: "James Agee",
    origin: "Excerpt from *A Death in the Family*",
    notes: "Via Sparknotes",
  },
  {
    id: "50",
    text: "The castle is on the very edge of a terrible precipice. A stone falling from the window would fall a thousand feet without touching anything! As far as the eye can reach is a sea of green tree tops, with occasionally a deep rift where there is a chasm. Here and there are silver threads where the rivers wind in deep gorges through the forests. But I am not in heart to describe beauty, for when I had seen the view I explored further; doors, doors, doors everywhere, and all locked and bolted. In no place save from the windows in the castle walls is there an available exit. The castle is a veritable prison, and I am a prisoner!",
    author: "Bram Stoker",
    origin: "Excerpt from *Dracula*",
    notes: "Via Sparknotes",
  },
  {
    id: "51",
    text: "The desert could not be claimed or owned\u2014it was a piece of cloth carried by winds, never held down by stones, and given a hundred shifting names before Canterbury existed, long before battles and treaties quilted Europe and the East\u2026. All of us, even those with European homes and children in the distance, wished to remove the clothing of our countries. It was a place of faith. We disappeared into landscape.",
    author: "Michael Ondaatje",
    origin: "Excerpt from *The English Patient*",
    notes: "Via Sparknotes",
  },
  {
    id: "52",
    text: "A novel is a mirror walking down a road\u2026Many books open with an author's assurance of order. One slipped into their waters with a silent paddle\u2026But novels commenced with hesitation or chaos. Readers were never fully in balance. A door a lock a weir opened and they rushed through, one hand holding a gunnel, the other a hat. When she begins a book, she enters through stilted doorways into large courtyards.",
    author: "Michael Ondaatje",
    origin: "Excerpt from *The English Patient*",
    notes: "Via Sparknotes",
  },
  {
    id: "53",
    text: "Every four days she washes his black body, beginning at the destroyed feet\u2026Above the shins the burns are worst. Beyond purple. Bone. She has nursed him for months and she knows the body well, the penis sleeping like a sea horse, the thin tight hips. Hipbones of Christ, she thinks. He is her despairing saint. He lies flat on his back, no pillow, looking up at the foliage painted onto the ceiling, its canopy of branches, and above that, blue sky.",
    author: "Michael Ondaatje",
    origin: "Excerpt from *The English Patient*",
    notes: "Via Sparknotes",
  },
  {
    id: "54",
    text: "Since I came to the throne, all I said, all my acts, have been aimed at building up an image of myself. I wish each of my subjects to keep that image in the foreground of his mind, and to feel, even when alone, that my eyes are on him, severely judging his most private thoughts. But I have been trapped in my own net. I have come to see myself only as they see me. I peer into the dark pit of their souls, and there, deep down, I see the image that I have built up. I shudder, but I cannot take my eyes off it. Almighty Zeus, who am I? Am I anything more than the dread that others have of me?",
    author: "Jean-Paul Sartre",
    origin: "Excerpt from *The Flies*",
    notes: "Via Sparknotes",
  },
  {
    id: "55",
    text: "In a way, it was a kind of ignoring that I did, an avoidance of her as Sunny\u2014difficult, rash, angry Sunny\u2014which I masked with a typical performance of consensus building and subtle pressure, which always is the difficult work of attempting to harmonize one\u2019s life and the lives of those whom one cherishes. It is the systematic operation, which always and obtusely succeeds, the well-planned response to life\u2019s uncertainties and complications.",
    author: "Chang-rae Lee",
    origin: "Excerpt from *A Gesture Life*",
    notes: "Via Sparknotes",
  },
  {
    id: "56",
    text: "Life [the prairie] held not; a magic ring lay on the horizon, extending upward into the sky; within this circle no living form could enter; it was like the chain enclosing the king's garden, that prevented it from bearing fruit. How could human beings continue to live here while that magic ring encompassed them? And those who were strong enough to break through were only being enticed still farther to their destruction.",
    author: "O.E. R\u00f6lvaag",
    origin: "Excerpt from *Giants in the Earth*",
    notes: "Via Sparknotes",
  },
  {
    id: "57",
    text: "There was only this perfect sympathy of movement, of turning this earth of theirs over and over to the sun, this earth which formed their home and fed their bodies and made their gods … Some time, in some age, bodies of men and women had been buried there, houses had stood there, had fallen, and gone back into the earth. So would also their house, some time, return into the earth, their bodies also. Each had his turn at this earth. They worked on, moving together\u2014together\u2014producing the fruit of this earth.",
    author: "Pearl S. Buck",
    origin: "Excerpt from *The Good Earth*",
    notes: "Via Sparknotes",
  },
  {
    id: "58",
    text: "\"But say a man does know. He sees the world as it is and he looks back thousands of years to see how it all come about. He watches the slow agglutination of capital and power and he sees its pinnacle today. He sees America as a crazy house... He sees a whole damn army of unemployed and billions of dollars and thousands of miles of land wasted... He sees how when people suffer just so much they get mean and ugly and something dies in them. But the main thing he sees is that the whole system of the world is built on a lie. And although it's as plain as the shining sun\u2014the don't-knows have lived with that lie so long they just can't see it.",
    author: "Carson McCullers",
    origin: "Excerpt from *The Heart Is a Lonely Hunter*",
    notes: "Via Sparknotes",
  },
  {
    id: "59",
    text: '"Our pride must be strong, for we know the value of the human mind and soul. We must teach our children. We must sacrifice so that they may earn the dignity of study and wisdom. For the time will come. The time will come when the riches in us will not be held in scorn and contempt. The time will come when we will be allowed to serve. When we will labor and our labor will not be wasted. And our mission is to await this time with strength and faith.',
    author: "Carson McCullers",
    origin: "Excerpt from *The Heart Is a Lonely Hunter*",
    notes: "Via Sparknotes",
  },
  {
    id: "60",
    text: '"You remember the four people I told you about when I was there... They are all very busy people. In fact they are so busy that it will be hard for you to picture them. I do not mean that they work at their jobs all day and night but that they have much business in their minds always that does not let them rest...the New York Caf\u00e9 owner is different. He watches. The others all have something they hate. And they all have something they love more than eating or sleeping or wine or friendly company. That is why they are always so busy.',
    author: "Carson McCullers",
    origin: "Excerpt from *The Heart Is a Lonely Hunter*",
    notes: "Via Sparknotes",
  },
  {
    id: "61",
    text: '"Then suddenly he felt a quickening in him. His heart turned and he leaned his back against the counter for support. For in a swift radiance of illumination he saw a glimpse of human struggle and of valor. Of the endless fluid passage of humanity through endless time. And of those who labor and of those who\u2014one word\u2014love. His soul expanded. But for a moment only. For in him he felt a warning, a shaft of terror... he was suspended between radiance and darkness. Between bitter irony and faith.',
    author: "Carson McCullers",
    origin: "Excerpt from *The Heart Is a Lonely Hunter*",
    notes: "Via Sparknotes",
  },
  {
    id: "62",
    text: "5. When we say men,man, manly, manhood, and all the other masculine-derivatives, we have in the background of our minds a huge vague crowded picture of the world and all its activities. … And when we say women, we thinkfemale\u2014the sex. But to these women … the word woman called up all that big background, so far as they had gone in social development; and the word man meant to them only male\u2014the sex.",
    author: "Charlotte Perkins Gilman",
    origin: "Excerpt from *Herland*",
    notes: "Via Sparknotes",
  },
  {
    id: "63",
    text: "Over everything\u2014up through the wreckage of the city, in gutters, along the riverbanks, tangled among tiles and tin roofing, climbing on charred tree trunks\u2014was a blanket of fresh, vivid, lush, optimistic green; the verdancy rose even from the foundations of ruined houses. Weeds already hid the ashes, and wild flowers were in bloom among the city\u2019s bones. The bomb had not only left the underground organs of the plants intact; it had stimulated them.",
    author: "John Hersey",
    origin: "Excerpt from *Hiroshima*",
    notes: "Via Sparknotes",
  },
  {
    id: "64",
    text: "She loves the world for being rude and indestructible, and she knows that other people must love it too, poor as well as rich, though no one speaks specifically of the reasons. Why else do we struggle to go on living, no matter how compromised, no matter how harmed? Even when we\u2019re further gone than Richard; even if we\u2019re fleshless, blazing with lesions, shitting in the sheets; still, we want desperately to live.",
    author: "Michael Cunningham",
    origin: "Excerpt from *The Hours*",
    notes: "Via Sparknotes",
  },
  {
    id: "65",
    text: "3. She can feel the nearness of the old devil (what else to call it?), and she knows she will be utterly alone if and when the devil chooses to appear again. The devil is a headache; the devil is a voice inside a wall; the devil is a fin breaking through dark waves. The devil is a brief, twittering nothing that is a thrush\u2019s life. The devil sucks all the beauty from the world, all the hope, and what remains when the devil has finished is a realm of the living dead\u2014joyless, suffocating.",
    author: "Michael Cunningham",
    origin: "Excerpt from *The Hours*",
    notes: "Via Sparknotes",
  },
  {
    id: "66",
    text: "He could hardly guess that the solemn, cubic, dense, pompous house, which sat like a hat amidst its green and geometric surroundings, would end up full of protuberances and incrustations, of twisted staircases that led to empty spaces, of turrets, or small windows and could not be opened, doors hanging in midair, crooked hallways, and portholes that linked the living quarters so that people could communicate during the siesta, all of which were Clara's inspiration.",
    author: "Isabel Allende",
    origin: "Excerpt from *The House of the Spirits*",
    notes: "Via Sparknotes",
  },
  {
    id: "67",
    text: "So, Laura thinks. So the papers have cleared and we are leaving. Now everything she sees sharpens as if through the lens of loss - the orchids in their hanging straw baskets, the row of apothecary jars Carlos has found for her in old druggists' throughout the countryside, the rich light shafts swarming with a golden pollen. She will miss this glorious light warming the inside of her skin and jewelling the trees, the grass, the lily pond beyond the hedge.",
    author: "Julia Alvarez",
    origin: "Excerpt from *How the Garcia Girls Lost Their Accents*",
    notes: "Via Sparknotes",
  },
  {
    id: "68",
    text: "What's it matter if the truth is that their favoring breeze has the stink of nickel whiskey on its breath, and their sea is a growler of lager and ale, and their ships are long since looted and scuttled and sunk on the bottom? To hell with the truth! As the history of the world proves, the truth has no bearing on anything. It's irrelevant and immaterial, as the lawyers say. The lie of a pipe dream is what gives life to the whole misbegotten mad lot of us, drunk or sober.",
    author: "Eugene O'Neill",
    origin: "Excerpt from *The Iceman Cometh*",
    notes: "Via Sparknotes",
  },
  {
    id: "69",
    text: "One of his last acts was to take a picture of himself, standing near the bus under the high Alaska sky, one hand holding his final note toward the camera lens, the other raised in a brave, beatific farewell. His face is horribly emaciated, almost skeletal. But if he pitied himself in those last difficult hours\u2014because he was so young, because he was alone, because his body had betrayed him and his will let him down\u2014it\u2019s not apparent from the photograph. He is smiling in the picture, and there is no mistaking the look in his eyes: Chris McCandless was at peace, serene as a monk gone to God. (199)",
    author: "Jon Krakauer",
    origin: "Excerpt from *Into the Wild*",
    notes: "Via Sparknotes",
  },
  {
    id: "70",
    text: "Until that summer, I had kept count of all the moons since the time my brother and I were alone on the island. For each one that came and went I cut a mark in a pole beside the door of my house. There were many marks, from the roof to the floor. But after that summer I did not cut them any more. The passing of the moons now had come to mean little, and I only made marks to count the four seasons of the year. The last year I did not count those.",
    author: "Scott O'Dell",
    origin: "Excerpt from *Island of the Blue Dolphins*",
    notes: "Via Sparknotes",
  },
  {
    id: "71",
    text: "Chamberlain raised his saber, let loose the shout that was the greatest sound he could make, boiling the yell up from his chest: Fix bayonets! Charge! Fix bayonets! Charge! Fix bayonets! Charge! He leaped down from the boulder, still screaming, his voice beginning to crack and give, and all around him his men were roaring animal screams, and he saw the whole Regiment rising and pouring over the wall and beginning to bound down through the dark bushes, over the dead and dying and wounded… .",
    author: "Michael Shaara",
    origin: "Excerpt from *The Killer Angels*",
    notes: "Via Sparknotes",
  },
  {
    id: "72",
    text: "I\u2019d have a stable full of Arabian steeds, rooms piled with books, and I\u2019d write out of a magic inkstand, so that my works should be as famous as Laurie\u2019s music. I want to do something splendid before I go into my castle\u2014something heroic, or wonderful\u2014that won\u2019t be forgotten after I\u2019m dead. I don\u2019t know what, but I\u2019m on the watch for it, and mean to astonish you all, some day. I think I shall write books, and get rich and famous; that would suit me, so that is my favorite dream.",
    author: "Louisa May Alcott",
    origin: "Excerpt from *Little Women*",
    notes: "Via Sparknotes",
  },
  {
    id: "73",
    text: "But it was above all at mealtimes that she could bear it no longer, in that little room on the ground floor, with the smoking stove, the creaking door, the oozing walls, the damp floor-tiles; all the bitterness of life seemed to be served to her on her plate, and, with the steam from the boiled beef, there rose from the depths of her soul other exhalations as it were of disgust. Charles was a slow eater; she would nibble a few hazel-nuts, or else, leaning on her elbow, would amuse herself making marks on the oilcloth with the point of her table-knife.",
    author: "Gustave Flaubert",
    origin: "Excerpt from *Madame Bovary*",
    notes: "Via Sparknotes",
  },
  {
    id: "74",
    text: "She hoped for a son; he would be strong and dark; she would call him George; and this idea of having a male child was like an expected revenge for all her impotence in the past. A man, at least, is free; he can explore all passions and all countries, overcome obstacles, taste of the most distant pleasures. But a woman is always hampered. Being inert as well as pliable, she has against her the weakness of the flesh and the inequity of the law. Like the veil held to her hat by a ribbon, her will flutters in every breeze; she is always drawn by some desire, restrained by some rule of conduct.",
    author: "Gustave Flaubert",
    origin: "Excerpt from *Madame Bovary*",
    notes: "Via Sparknotes",
  },
  {
    id: "75",
    text: "All right, so he\u2019s down on his luck! I\u2019m sorry. I don\u2019t mind saying that: I\u2019m sorry! Bad luck! If I\u2019d any good luck to spare he could have some. I wish we could all have good luck, all the time! I wish we had wings! I wish rainwater was beer! But it isn\u2019t! … And what with not having wings but walking-on two flat feet; and good luck and bad luck being just exactly even stevens; and rain being water\u2014don\u2019t you complicate the job by putting things in me for me to miss! (Act Two, scene two)",
    author: "Robert Bolt",
    origin: "Excerpt from *A Man for All Seasons*",
    notes: "Via Sparknotes",
  },
  {
    id: "76",
    text: "Oh, I'd love to see the whole of your sex swimming in a sea of blood just like that. I think I could drink out of your skull You think I loved you because my womb hungered for your seed Bear your child and take your name!\u2014Come to think of it, what is your name anyway? I've never heard your last name. You probably don't even have one. I'd be Mrs. Doorkeeper or Madame Floorsweeper. You dog with my name on your collar\u2014you lackey with my initials on your buttons!",
    author: "August Strindberg",
    origin: "Excerpt from *Miss Julie*",
    notes: "Via Sparknotes",
  },
  {
    id: "77",
    text: "Although I am nine months pregnant, although I have had plenty of time to dream, I have not really considered the specifics of this child. I have thought of this daughter only in terms of what she will be able to do for the daughter I already have\u2026Then again, my dreams for her are no less exalted; I plan for her to save her sister\u2019s life.",
    author: "Jodi Picoult",
    origin: "Excerpt from *My Sister\u2019s Keeper*",
    notes: "Via Sparknotes",
  },
  {
    id: "78",
    text: "We all know that something is eternal. And it ain\u2019t houses and it ain\u2019t names, and it ain\u2019t earth, and it ain\u2019t even the stars … everybody knows in their bones that something is eternal, and that something has to do with human beings. All the greatest people ever lived have been telling us that for five thousand years and yet you\u2019d be surprised how people are always losing hold of it. There\u2019s something way down deep that\u2019s eternal about every human being.",
    author: "Thornton Wilder",
    origin: "Excerpt from *Our Town*",
    notes: "Via Sparknotes",
  },
  {
    id: "79",
    text: "Heat stood in the room like an enemy. But he believed against the evidence of his senses in the cold empty ether spaces. A radio was playing somewhere: music from Mexico City, or perhaps even from London or New York filtered into this obscure neglected state. It seemed to him like a weakness: this was his own land, and he would have walled it in with steel if he could, until he had eradicated from it everything which reminded him of how it had once appeared to a miserable child. He wanted to destroy everything: to be alone without any memories at all.",
    author: "Graham Greene",
    origin: "Excerpt from *The Power and the Glory*",
    notes: "Via Sparknotes",
  },
  {
    id: "80",
    text: "He dreamed that the priest whom they had shot that morning was back in the house dressed in the clothes his father had lent him and laid out stiffly for burial. The boy sat beside the bed and his mother read out of a very long book all about how the priest had acted in front of the bishop the part of Julius Caesar: there was a fish basket at her feet, and the fish were bleeding, wrapped in her handkerchief. He was very bored and very tired and somebody was hammering nails into a coffin in the passage. Suddenly the dead priest winked at him\u2014an unmistakable flicker of the eyelid, just like that.",
    author: "Graham Greene",
    origin: "Excerpt from *The Power and the Glory*",
    notes: "Via Sparknotes",
  },
  {
    id: "81",
    text: "As is so often the case with a legend, every incident has two possible interpretations, the plausible and the one that is molded to suit the making of the myth. Man is a romantic at heart and will always put aside dull, plodding reason for the excitement of an enigma. As Doc had pointed out, mystery, not logic, is what gives us hope and keeps us believing in a force greater than our own insignificance.",
    author: "Bryce Courtenay",
    origin: "Excerpt from *The Power of One*",
    notes: "Via Sparknotes",
  },
  {
    id: "82",
    text: "Only the expenditure of one\u2019s own resources is harmful; and, indeed, nothing feeds upon itself as liberality does. The more it is indulged, the fewer are the means to indulge it further. As a consequence, a prince becomes poor and contemptible or, to escape poverty, becomes rapacious and hateful. Of all the things he must guard against, hatred and contempt come first, and liberality leads to both. Therefore it is better to have a name for miserliness, which breeds disgrace without hatred, than, in pursuing a name for liberality, to resort to rapacity, which breeds both disgrace and hatred.",
    author: "Niccol\u00f2 Machiavelli",
    origin: "Excerpt from *The Prince*",
    notes: "Via Sparknotes",
  },
  {
    id: "83",
    text: "Here a question arises: whether it is better to be loved than feared, or the reverse. The answer is, of course, that it would be best to be both loved and feared. But since the two rarely come together, anyone compelled to choose will find greater security in being feared than in being loved. … Love endures by a bond which men, being scoundrels, may break whenever it serves their advantage to do so; but fear is supported by the dread of pain, which is ever present.",
    author: "Niccol\u00f2 Machiavelli",
    origin: "Excerpt from *The Prince*",
    notes: "Via Sparknotes",
  },
  {
    id: "84",
    text: "My poor friend, give up this angry mood of yours and look for means of getting yourself free of trouble. Maybe what I say seems to you both old and commonplace; but this is what you pay, Prometheus, for that tongue of yours which talked so high and haughty: you are not yet humble, still you do not yield to your misfortunes, and you wish, indeed, to add some more to them; now, if you follow me as a schoolmaster you will not kick against the pricks, seeing that he, the King, that rules alone, is harsh and sends accounts to no one's audit for the deeds he does.",
    author: "Aeschylus",
    origin: "Excerpt from *Prometheus Bound*",
    notes: "Via Sparknotes",
  },
  {
    id: "85",
    text: "Why do you suppose an idea which had currency in every age and civilization of mankind disappears in modern times? Because only in the age of science have these men and their wisdom dropped from view. I'll tell you why: The rise of mechanistic science, of Newton and Descartes, was a great conspiracy, a great devilish conspiracy to destroy our apprehension of reality and our awareness of the transcendentally gifted among us. But they are with us today nevertheless. They are with us in every age. They come back, you see? They come back!",
    author: "E. L. Doctorow",
    origin: "Excerpt from *Ragtime*",
    notes: "Via Sparknotes",
  },
  {
    id: "86",
    text: "He wandered through the house finding everywhere signs of his own exclusion. His son now had a desk, as befitted all young students. He thought he heard an Arctic wind but it was the sound of the housemaid Brigit pushing an electric suction cleaner across the rug in the parlor. What was the strangest of all was the mirror in his bath: it gave back the gaunt, bearded face of a derelict, a man who lacked a home.",
    author: "E. L. Doctorow",
    origin: "Excerpt from *Ragtime*",
    notes: "Via Sparknotes",
  },
  {
    id: "87",
    text: "He looked in Mother's eyes to detect there his justice. He found instead a woman curious and alert to his new being. He realized that every night since he's returned they had slept in the same bed. She was in some way not as vigorously modest as she'd been. She took his gaze. She came to bed with her hair unbraided. Her hand one night brushed down his chest and same to rest below his nightshirt. He decided that God had punishments in store so devious there was no sense trying to anticipate what they were. With a groan he turned to her and found her ready. Her hand pulling his face to hers did not feel his tears.",
    author: "E. L. Doctorow",
    origin: "Excerpt from *Ragtime*",
    notes: "Via Sparknotes",
  },
  {
    id: "88",
    text: "One of the paradoxes of war\u2014one of the many\u2014was that this most brutal of conflicts should set up a relationship between officers and men that was\u2026 domestic. Caring. As Layard would undoubtedly have said, maternal. And that wasn't the only trick the war had played. Mobilization. The Great Adventure. They'd been mobilized into holes in the ground so constricting they could hardly move. And the Great Adventure\u2014the real life equivalent of all the adventure stories they'd devoured as boys\u2014consisted of crouching in a dugout, waiting to be killed.",
    author: "Pat Barker",
    origin: "Excerpt from *Regeneration*",
    notes: "Via Sparknotes",
  },
  {
    id: "89",
    text: "\"'He was my enemy.' he was saying, 'but he always behaved like a gentleman. We treated each other decently over six months of shelling each other. He was a gentleman doing his job and I bore him no malice. I said to him: \"Look here, we're enemies now and I'll fight you with all I've got. But when this wretched business is over, we shan't have to be enemies any more and we'll have a drink together.\" Wretched thing is, this treaty is making a liar out of me. I mean to say, I told him we wouldn't be enemies once it was all over. But how can I look him in the face and tell him that's turned out to be true?'",
    author: "Kazuo Ishiguro",
    origin: "Excerpt from *The Remains of the Day*",
    notes: "Via Sparknotes",
  },
  {
    id: "90",
    text: "They don\u2019t understand that a true captain must pay attention to the seasons of the year, the sky, the stars, the winds, and all that pertains to his craft, if he\u2019s really to be the ruler of a ship. And they don\u2019t believe that there is any craft that would enable him to determine how he should steer the ship, whether the others want him to or not, or any possibility of mastering this alleged craft or of practicing it at the same time as the craft of navigation. Don\u2019t you think that the true captain will be called a real stargazer, a babbler, and a good-for-nothing by those who sail in ships governed in that way?",
    author: "Plato",
    origin: "Excerpt from *The Republic*",
    notes: "Via Sparknotes",
  },
  {
    id: "91",
    text: "If her functioning as a female is not enough to define woman, if we decline also to explain her through \u201cthe eternal feminine,\u201d and if nevertheless we admit, provisionally, that women do exist, then we must face the question: what is a woman? … The fact that I ask it is in itself significant. A man would never get the notion of writing a book on the peculiar situation of the human male. But if I wish to define myself, I must first of all say, \u201dI am a woman\u201d; on this truth must be based all further discussion.",
    author: "Simone de Beauvoir",
    origin: "Excerpt from *The Second Sex*",
    notes: "Via Sparknotes",
  },
  {
    id: "92",
    text: "'There's two ways of living here now. There's the old way, look out for your family, die where you was born, fish, cut your wood, keep a garden, make do with what you got. Then there's the new way. Work out, have a job, somebody tell you what to do, your brother's in South Africa, your mother's in Regina, buy every goddamn cockadoodle piece of Japanese crap can. Leave home. Go off to look for work. And some has a hard time of it. … Now we got to deal with Crock-Pots and consumer ratings, asphalt driveways, lotteries, fried chicken franchises, Mint Royale coffee and gourmet shops, all that stuff.'",
    author: "Annie Proulx",
    origin: "Excerpt from *The Shipping News*",
    notes: "Via Sparknotes",
  },
  {
    id: "93",
    text: "we have this illusion of being one person for all, of having a personality that is unique in all our acts. But it isn't true. We perceive this when, tragically perhaps, in something we do, we are as it were, suspended, caught up in the air on a kind of hook. We perceive that all of us was not in that act, and that it would be an atrocious injustice to judge us by that action alone, as if all our existence were summed up in that one deed.",
    author: "Luigi Pirandello",
    origin: "Excerpt from *Six Characters in Search of an Author*",
    notes: "Via Sparknotes",
  },
  {
    id: "94",
    text: "Billy had a framed prayer on his office wall which expressed his method for keeping going, even though he was unenthusiastic about living. A lot of patients who saw the prayer on Billy\u2019s wall told him that it helped them to keep going, too. It went like this: \u201cGod grant me the serenity to accept the things I cannot change, courage to change the things I can, and wisdom always to tell the difference.\u201d Among the things Billy Pilgrim could not change were the past, the present, and the future.",
    author: "Kurt Vonnegut",
    origin: "Excerpt from *Slaughterhouse-Five*",
    notes: "Via Sparknotes",
  },
  {
    id: "95",
    text: "5. It is as if, then, the beauty\u2014the beauty of the sea, the land, the air, the trees, the market, the people, the sounds they make\u2014were a prison, and as if everything and everybody inside it were locked in and everything and everybody that is not inside it were locked out. And what might it do to ordinary people to live in this way every day? What might it do to them to live in such heightened, intense surroundings every day?",
    author: "Jamaica Kincaid",
    origin: "Excerpt from *A Small Place*",
    notes: "Via Sparknotes",
  },
  {
    id: "96",
    text: "As if that blind rage had washed me clean, rid me of hope; for the first time, in that night alive with signs and stars, I opened myself to the gentle indifference of the world. Finding it so much like myself\u2014so like a brother, really\u2014I felt that I had been happy and that I was happy again. For everything to be consummated, for me to feel less alone, I had only to wish that there be a large crowd of spectators the day of my execution and that they greet me with cries of hate.",
    author: "Albert Camus",
    origin: "Excerpt from *The Stranger*",
    notes: "Via Sparknotes",
  },
  {
    id: "97",
    text: '"It is later than you think" could not be expressed in Martian\u2014nor could "Haste makes waste," though for a different reason: the first notion was inconceivable while the latter was an unexpressed Martian basic, as unnecessary as telling a fish to bathe. But "As it was in the Beginning, is now and ever shall be" was so Martian in mood that it could be translated more easily than "two plus two makes four"\u2014which was not a truism on Mars.',
    author: "Robert A. Heinlein",
    origin: "Excerpt from *Stranger in a Strange Land*",
    notes: "Via Sparknotes",
  },
  {
    id: "98",
    text: "His idea is that whenever you encounter any other grokking thing\u2014man, woman, or stray cat\u2026you are meeting your 'other end.' The universe is a thing we whipped up among us and agreed to forget the gag.\" Jubal looked sour. \"Solipsism and pantheism. Together they explain anything. Cancel out any inconvenient fact, reconcile all theories, include any facts or delusions you like. But it's cotton candy, all taste and no substance\u2014as unsatisfactory as solving a story by saying: '\u2014then the little boy fell out of bed and woke up.'",
    author: "Robert A. Heinlein",
    origin: "Excerpt from *Stranger in a Strange Land*",
    notes: "Via Sparknotes",
  },
  {
    id: "99",
    text: 'The sky held scattered clouds; at that instant the sun came out from behind one and a shaft of light hit him. His clothes vanished. He stood before them, a golden youth, clothed only in beauty\u2014beauty that made Jubal\'s heart ache, thinking that Michelangelo in his ancient years would have climbed down from his high scaffolding to record it for generations unborn. Mike said gently, "Look at me. I am a son of man." The scene cut for a ten-second plug, a line of can-can dancers singing',
    author: "Robert A. Heinlein",
    origin: "Excerpt from *Stranger in a Strange Land*",
    notes: "Via Sparknotes",
  },
  {
    id: "100",
    text: "I request America to look into our hearts and see that the great majority of us are not terrorists, but good and simple people. Our land is stricken with poverty because we are without education. But today, another candle of knowledge has been lit. In the name of Allah the Almighty, may it light our way out of the darkness we find ourselves in.",
    author: "Syed Abbas",
    origin: "Sourced from from *Three Cups of Tea*",
    notes: "Via Sparknotes",
  },
  {
    id: "101",
    text: "The one tree in Francie's yard was neither a pine nor a hemlock. It had pointed leaves which grew along green switches which radiated from the bough and made a tree which looked like a lot of opened green umbrellas. Some people called it the Tree of Heaven. No matter where its seed fell, it made a tree which struggled to reach the sky. It grew in boarded-up lots and out of neglected rubbish heaps and it was the only tree that grew out of cement. It grew lushly, but only in the tenement districts.",
    author: "Betty Smith",
    origin: "Excerpt from *A Tree Grows in Brooklyn*",
    notes: "Via Sparknotes",
  },
  {
    id: "102",
    text: "As I walked home, I thought about the message. In the course of a lifetime, what does it matter? I said it over and over. I wondered about the mysterious messenger, and I thought about all the things in the course of a lifetime that would not matter. I did not think cheerleading tryouts would matter, but I was not so sure about yelling at your mother. I was certain, however, that if your mother left, it would be something that mattered in the whole long course of your lifetime.",
    author: "Sharon Creech",
    origin: "Excerpt from *Walk Two Moons*",
    notes: "Via Sparknotes",
  },
  {
    id: "103",
    text: "A rabbit has two ears; a rabbit has two eyes, two nostrils. Our two warrens ought to be like that. They ought to be together\u2014not fighting. We ought to make other warrens between us\u2014start one between here and Efrafa, with rabbits from both sides. You wouldn't lose by that, you'd gain. We both would. A lot of your rabbits are unhappy now and it's all you can do to control them, but with this plan you'd soon see a difference. Rabbits have enough enemies as it is. They ought not to make more among themselves. A mating between free, independent warrens\u2014what do you say?",
    author: "Richard Adams",
    origin: "Excerpt from *Watership Down*",
    notes: "Via Sparknotes",
  },
  {
    id: "104",
    text: "Everything about her had two sides to it, one for home and one for anywhere that was not home: her walk, which could be childlike and bobbing, or languid enough to make anyone think she was hearing music in her head; her mouth, which was pale and smirking most of the time, but bright and pink on these evenings out; her laugh, which was cynical and drawling at home … but high-pitched and nervous anywhere else …",
    author: "Joyce Carol Oates",
    origin: "Excerpt from *Where Are You Going, Where Have You Been?*",
    notes: "Via Sparknotes",
  },
  {
    id: "105",
    text: "There is no looking glass here and I don't know what I am like now. I remember watching myself brush my hair and how my eyes looked back at me. The girl I saw was myself yet not quite myself. Long ago when I was a child and very lonely I tried to kiss her. But the glass was between us\u2014hard, cold and misted over with my breath. Now they have taken everything away. What am I doing in this place and who am I?",
    author: "Jean Rhys",
    origin: "Excerpt from *Wide Sargasso Sea*",
    notes: "Via Sparknotes",
  },
  {
    id: "106",
    text: "I hated the mountains and the hills, the rivers and the rain. I hated the sunsets of whatever colour, I hated its beauty and its magic and the secret I would never know. I hated its indifference and the cruelty which was part of its loveliness. Above all I hated her. For she belonged to the magic and the loveliness. She had left me thirsty and all my life would be thirst and longing for what I had lost before I found it.",
    author: "Jean Rhys",
    origin: "Excerpt from *Wide Sargasso Sea*",
    notes: "Via Sparknotes",
  },
  {
    id: "107",
    text: "Be careful what you say. It comes true. It comes true. I had to leave home in order to see the world logically, logic the new way of seeing. I learned to think that mysteries are for explanation. I enjoy the simplicity. Concrete pours out of my mouth to cover the forests with freeways and sidewalks. Give me plastics, periodical tables, TV dinners with vegetables no more complex than peas mixed with diced carrots. Shine floodlights into dark corners: no ghosts.",
    author: "Maxine Hong Kingston",
    origin: "Excerpt from *The Woman Warrior*",
    notes: "Via Sparknotes",
  },
  {
    id: "108",
    text: "If a physician of high standing, and one\u2019s own husband, assures friends and relatives that there is really nothing the matter with one but temporary nervous depression\u2014a slight hysterical tendency\u2014what is one to do? … So I take phosphates or phosphites\u2014whichever it is, and tonics, and journeys, and air, and exercise, and am absolutely forbidden to \u201cwork\u201d until I am well again. Personally, I disagree with their ideas …",
    author: "Charlotte Perkins Gilman",
    origin: "Excerpt from *The Yellow Wallpaper*",
    notes: "Via Sparknotes",
  },
  {
    id: "109",
    text: "Once again I've let a few lazy days slip by without accomplishing anything. You know, the kind of days when you sleep late because there's nothing important to do, then go out until the room has been made up, and then study until fatigue sets in. Then you loaf around for a while and half-heartedly look forward to dinner, listlessly contemplating highly philosophical questions while whistling a little ... How was I able to live alone before, my little everything? Without you I lack self-confidence, passion for work, and enjoyment of life—in short, without you, my life is no life.",
    author: "Albert Einstein",
    origin:
      "Excerpt from Einstein's letter to *Mileva Marić*. Translated by *Shawn Smith*",
    notes: "From Agnes's book collection.",
  },
  {
    id: "110",
    text: "Often we think we hear in music only what exists in the words, or the interpretation we wish to give them. We try to subject music to forced inflections, but that is not the way to be able to judge it. On the contrary, we must not think but let ourselves be carried away by the feeling that the music inspires; without our thinking at all, this feeling will become the basis of our judgement. As for reason, everybody possesses it nowadays; we just have discovered it in the bosom of nature itself. We have even proved that instinct constantly recalls it to us, both in our actions and in our speech. When reason and instinct are reconciled, there will be no higher appeal.",
    author: "Jean Philippe Rameau",
    origin:
      "Sourced from *Composers on Music: Eight Centuries of Writing*, edited by *Josiah Fisk*",
    notes: "From Agnes's book collection.",
  },
  {
    id: "111",
    text: "How seldom do we meet with a proper amount of sympathy, knowledge, honesty, and courage in a critic — four qualities they ought, in any event, to possess to some extent. It is sad indeed for the world of music that criticism, in so many respects so useful, should often be the occupation of persons in no way endowed with these qualities.",
    author: "Carl Philipp Emanuel Bach",
    origin:
      "Sourced from *Composers on Music: Eight Centuries of Writing*, edited by *Josiah Fisk*",
    notes: "From Agnes's book collection.",
  },
];

export default plaintexts;
